import { mapActions, mapGetters } from 'vuex';

const AwesomeVuetifyForm = () => import('@/plugins/awesome-vuetify-form');

export default {
  name: 'local-guest-template',
  components: {
    AwesomeVuetifyForm,
  },
  data: function () {
    return {
      valid: false,
      rules: {
        name: [
          v => (v ? true : this.$t('Field is required')),
          v =>
            v && v.length >= 3 ? true : this.$t('Name must be at least greater than 3 characters'),
        ],
        captcha: [v => (v ? true : this.$t('Field is required'))],
      },

      name: '',
      captcha: '',
      loader: false,
    };
  },
  computed: {
    ...mapGetters('captcha', ['captchaId', 'captchaImg', 'error']),
  },
  methods: {
    ...mapActions('captcha', ['fetch', 'check']),

    getNewCaptchaId: async function () {
      this.loader = true;
      this.captcha = null;
      await this.fetch();
      this.loader = false;
    },

    validate: async function () {
      if (this.error) this.captcha = null;
      return this.$refs.form.validate() && !this.error;
    },

    onSignIn: function () {
      this.$emit('input', {
        name: this.name,
        captcha: this.captcha,
        captchaId: this.captchaId,
      });
      this.$emit('onSignIn');
    },
  },
  watch: {
    name: function (val) {
      this.$emit('input', {
        name: val,
        captcha: this.captcha,
        captchaId: this.captchaId,
      });
    },

    captcha: function (val) {
      this.$emit('input', {
        name: this.name,
        captcha: val,
        captchaId: this.captchaId,
      });
    },
  },
  created: function () {
    this.getNewCaptchaId();
  },
};
